import "./PageNotFound404.css";

const PageNotFound404 = () => {
    return (
        <div className="Page-not-found-404">
            <h1>Error 404</h1>
            <h2>Page not found</h2>
        </div>
    )
}

export default PageNotFound404;